import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Header from '../components/Header';
import Logo from '../assets/YAIR_LOGO.svg';

const ns = 'index-page';

const IndexPage = ({ location }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  });

  return (
    <Layout location={location} title="YAIR">
      <Seo title="YAIR" />
      <div className={rootClassnames}>
        <Header />
        <div className={`${ns}__logo`}>
          <div className={`${ns}__logo-container`}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default IndexPage;

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};
